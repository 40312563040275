import React, { useState } from "react";
import { AddFlex, CustomText, PrimaryActionButton } from "../reusableStyles";
import styled, { keyframes } from "styled-components";
import { primaryActionColor } from "../constants";
import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  sendSignInLinkToEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { authenticate } from "../firebase-config";
import { enqueueSnackbar } from "notistack";
import { isNullOrUndefined } from "../utils";
import { Player } from "@lottiefiles/react-lottie-player";
import { CircularProgress } from "@mui/material";
import { ArrowRight, ArrowRightAlt } from "@mui/icons-material";
import useWindowSize from "./useWindowSize";
import { useLocation } from "react-router";

const StyledInput = styled.input`
  height: 40px;
  border-radius: 5px;
  border: 1px solid #ececec;
  outline: none;
  padding: 5px 15px;
  margin: 5px 0 0 0;
  &:focus {
    border: 1px solid ${primaryActionColor};
  }
  &::placeholder {
    opacity: 0.6;
  }
`;

const StyledLabel = styled.label`
  font-size: 14px;
`;
const StyledForm = styled.form`
  display: flex;
  margin: 30px 0 0 0;
  row-gap: 5px;
  width: 100%;
  flex-direction: column;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

const StyledButton = styled.button`
  /* margin: 40px 0 0 0; */
  border-radius: 5px;
  border: none;
  padding: 5px 15px;
  height: 40px;
  background-color: ${primaryActionColor};
  color: white;
  cursor: pointer;
`;

const Container = styled.div`
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const expandAnimation = keyframes`
  0% {
    width: 0;
    height: 0;
  }
  100% {
    width: 200vw;
    height: 200vh;
  }
`;

const Circle = styled.div`
  position: fixed;
  width: 0;
  height: 0;
  border-radius: 50%;
  z-index: 1111;
  background: #2f68cc;
  animation: ${expandAnimation} 1s forwards;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

function EmailLoginComponent({ isLogin, clientDetails, authenticateUser }) {
  const [email, setEmail] = useState("");
  const { isMobileView } = useWindowSize();
  const [startAnimation, setStartAnimation] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [err, setErr] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const getEmailView = () => {
    if (process.env.NODE_ENV === "production") {
      return clientDetails?.domain
        ? `http://${clientDetails?.domain}?isVerify=true`
        : "https://login.growezy.club?isVerify=true";
    } else {
      return "http://localhost:3000?isVerify=true";
    }
  };

  const handleLogInWithEmailPassword = async () => {
    setLoading(true);
    const actionCodeSettings = {
      url: getEmailView(),
      handleCodeInApp: true,
    };
    sendSignInLinkToEmail(authenticate, email, actionCodeSettings)
      .then(() => {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        handleClick();
        // enqueueSnackbar("Signin link has been sent to your mobile number.", {
        //   variant: "success",
        //   anchorOrigin:{

        //   }
        // });
        window.localStorage.setItem("emailForSignIn", email);
        // ...
      })
      .catch((error) => {
        console.log(error);
        const errorCode = error.code;
        const errorMessage = error.message;
        // ...
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSignInWithEmailAndPassword = async () => {
    setLoading(true);
    await signInWithEmailAndPassword(authenticate, email, password)
      .then(async (loginResponse) => {
        authenticateUser(loginResponse);
      })
      .catch((error) => {
        const errorMessage = error.message;
        console.log(errorMessage);
        enqueueSnackbar("Invalid Credentails");
        setLoading(false);
      });
  };

  const getErrorMessage = (error) => {
    if (isNullOrUndefined(error)) {
      return "Error in creating account, Please try again later";
    } else if (error.code === "auth/weak-password") {
      return "Password must be atleast 6 digits length";
    } else if (error.code === "auth/email-already-in-use") {
      return "Email is already in use. Try other email.";
    } else {
      return "Error in creating account, Please try again later";
    }
  };

  const handleSignUpWithEmailPassword = async () => {
    setLoading(true);
    await createUserWithEmailAndPassword(authenticate, email, password)
      .then(async (loginResponse) => {
        // authenticateUser(loginResponse);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
        const message = getErrorMessage(error);
        enqueueSnackbar(message);
      });
  };

  const handleForgotPassword = () => {
    if (email.length === 0) {
      setErr(
        "Please enter email, password reset link will be sent to your email."
      );
      return;
    }
    sendPasswordResetEmail(authenticate, email)
      .then(() => {
        // Password reset email sent!
        // ..
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
      });
  };

  const handleClick = () => {
    setStartAnimation(true);
    setTimeout(() => {
      setShowContent(true);
    }, 1000); // Duration of the animation
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // setStartAnimation(true);
    if (isLogin) {
      handleSignInWithEmailAndPassword();
    } else {
      handleSignUpWithEmailPassword();
    }
  };

  return (
    <>
      {err && isLogin && <CustomText color="red">{err}</CustomText>}
      <StyledForm margin="0px 0 0 0" onSubmit={handleSubmit}>
        <StyledLabel
          style={{ fontSize: "16px", color: "#000", fontWeight: "400" }}
        >
          Please enter your Email ID
        </StyledLabel>
        {startAnimation && <Circle />}
        {showContent && (
          <AddFlex
            position="fixed"
            flexDirection="column"
            top="0px"
            left="0px"
            // justify="center"
            alignItems="center"
            height="100vh"
            width="100vw"
            style={{ zIndex: 111111 }}
          >
            <Player
              autoplay
              loop
              src="https://lottie.host/9b17a749-f6f4-4beb-acc9-eba38587434a/idtoTm0Zrf.json"
              style={{
                height: "200px",
                width: "200px",
                margin: "100px 0 0 0",
              }}
            ></Player>
            <CustomText
              color="white"
              fontSize="32px"
              fontWeight="600"
              margin="0"
            >
              Verification Email Sent.
            </CustomText>
            <CustomText
              color="white"
              style={{
                width: isMobileView() ? "85%" : "28%",
                lineHeight: "1.4",
              }}
              fontSize="18px"
              fontWeight="400"
              textAlign="center"
            >
              Please open your email, you will find a verification link. Verify
              your email using that link.
            </CustomText>
            <AddFlex
              margin="20px 0 0 0"
              color="white"
              onClick={() => {
                setShowContent(false);
                setStartAnimation(false);
              }}
              style={{
                borderBottom: "1px solid white",
                fontWeight: "500",
                cursor: "pointer",
              }}
            >
              Close
            </AddFlex>
          </AddFlex>
        )}
        <StyledInput
          type="email"
          placeholder="jhon.doe@gmail.com"
          style={{ fontSize: "16px" }}
          required={true}
          onChange={(e) => {
            setErr(null);
            setEmail(e.target.value);
          }}
        />
        <StyledLabel
          style={{ fontSize: "16px", marginTop: "10px", color: "#444" }}
        >
          Password
        </StyledLabel>
        <StyledInput
          type="password"
          placeholder="Enter password"
          style={{ fontSize: "16px" }}
          required
          onChange={(e) => {
            setErr(null);
            setPassword(e.target.value);
          }}
        />
        <PrimaryActionButton
          type="submit"
          style={{
            fontSize: "16px",
            textTransform: "none",
            height: "44px",
            marginTop: "20px",
          }}
        >
          {loading ? (
            <CircularProgress size={18} sx={{ color: "white" }} />
          ) : (
            <AddFlex gap="12px" alignItems="center">
              Login <ArrowRightAlt />
            </AddFlex>
          )}
        </PrimaryActionButton>
        {/* {isLogin && (
          <CustomText
            fontSize="12px"
            margin="5px 0 0 auto"
            onClick={handleForgotPassword}
          >
            Forgot Password?
          </CustomText>
        )} */}
      </StyledForm>
    </>
  );
}

export default EmailLoginComponent;
