import React from "react";
import styled from "styled-components";
import { inputHeight, inputPadding } from "../constants";
import google from "../ImageAssets/google.png";
import { AddFlex, CustomText } from "../reusableStyles";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { authenticate } from "../firebase-config";
import { Button } from "@mui/material";

const StyledGoogleLoginButton = styled.button`
  height: ${inputHeight};
  padding: ${inputPadding};
  background-color: black;
  color: white;
  border: none;
  border-radius: 5px;
  width: 80%;
  flex-shrink: 0;
  cursor: pointer;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

const StyledImage = styled.img`
  width: 16px;
  aspect-ratio: 1;
`;

function GoogleLoginComponent({ authenticateUser }) {
  const signInWithGoogle = async () => {
    var provider = new GoogleAuthProvider();
    await signInWithPopup(authenticate, provider)
      .then(async (loginResponse) => {
        authenticateUser(loginResponse, "gmail");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <Button
      variant="outlined"
      style={{
        width: "100%",
        fontSize: "16px",
        textTransform: "none",
        strokeWidth: "3",
      }}
      onClick={async () => await signInWithGoogle()}
      startIcon={<StyledImage src={google} alt="growezy-login-google" />}
    >
      Continue With Google
    </Button>
  );
}

export default GoogleLoginComponent;
